





















































































import InputGroup from "@/components/InputGroup.vue";
import ParentView from "@/components/ParentView.vue";
import useProfile from "@/composables/useProfile";
import injectStrict from "@/utilities/injectstrict";
import { maxLength, required } from "@/utilities/validators";
import { defineComponent, toRefs, ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { ProfileKey } from "../symbols";
import { useResendEmailMutation } from "@/graphql/types";

export default defineComponent({
  components: { ParentView, InputGroup },
  setup() {
    const { canChangeProfileEmail, canResendVerifyEmail } = useProfile();
    const user = injectStrict(ProfileKey);
    const v$ = useVuelidate(useValidations(), { ...toRefs(user) });

    const resendComplete = ref(false);
    const { mutate, loading, onDone } = useResendEmailMutation({});
    const { profile } = useProfile();

    function resend() {
      mutate({
        input: profile.value?.id,
      });
    }

    onDone(() => {
      resendComplete.value = true;
    });

    return {
      canChangeProfileEmail,
      user,
      v$,
      canResendVerifyEmail,
      resendComplete,
      loading,
      resend,
    };
  },
});

function useValidations() {
  return {
    firstname: { required, maxLength: maxLength(255) },
    lastname: { required, maxLength: maxLength(255) },
    description: { maxLength: maxLength(1024) },
  };
}
