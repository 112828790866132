










































import InputGroup from "@/components/InputGroup.vue";
import Select from "@/components/Select.vue";
import Table from "@/components/Table.vue";
import {
  Application,
  Organization,
  useTokenSimulationQuery,
} from "@/graphql/types";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { ProfileKey } from "../symbols";

export default defineComponent({
  components: { Table, InputGroup, Select },
  setup(_, { root }) {
    const user = injectStrict(ProfileKey);

    const application = ref(null as Application | null);
    const organization = ref(null as Organization | null);

    const { result, loading } = useTokenSimulationQuery(
      () => ({
        organizationId: organization.value?.id,
        applicationId: application.value?.id,
      }),
      () => ({
        enabled: organization.value != null && application.value != null,
      })
    );

    return {
      application,
      organization,
      loading,
      applications: computed(() => {
        return Object.values(
          user.roleAssignments?.reduce(function (
            map: { [id: string]: Application },
            obj
          ) {
            if (
              obj &&
              obj.role?.application &&
              map[obj.role.application.id] == null
            ) {
              map[obj.role.application.id] = obj.role.application;
            }

            return map;
          },
          {}) ?? {}
        );
      }),
      organizations: computed(() => {
        return Object.values(
          user.roleAssignments?.reduce(function (
            map: { [id: string]: Organization },
            obj
          ) {
            if (obj && obj.organization && map[obj.organization?.id] == null) {
              map[obj.organization.id] = obj.organization;
            }

            return map;
          },
          {}) ?? {}
        );
      }),
      claims: useResult(result, [], (data) => data.userSimulationToken),
      fields: computed<TableField[]>(() => [
        {
          key: "key",
          label: root.$tc("common.claim_type"),
          sortable: true,
          filter: false,
        },
        {
          key: "value",
          label: root.$tc("common.claim_value"),
          sortable: true,
          filter: false,
        },
      ]),
    };
  },
});
