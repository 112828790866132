<template>
  <div class="d-flex align-items-center">
    <font-awesome-icon icon="thumbs-up" size="lg" class="mr-4" />
    {{ message }}
    <b-button
      class="text-muted ml-auto bg-transparent border-0"
      @click="$emit('close-toast')"
    >
      <font-awesome-icon icon="times" />
    </b-button>
  </div>
</template>

<script>
import Vue from "vue";

// This component is written using pure javascript, and options api due to annoying il8n errors and incompatilibies with vue 2.0.

export default Vue.extend({
  props: {
    message: {
      type: String,
      required: false,
    },
  },
});
</script>
