


























import Table from "@/components/Table.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Maybe, Organization, UserRoleAssignment } from "@/graphql/types";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { computed, defineComponent } from "@vue/composition-api";
import { ProfileKey } from "../symbols";

interface MappedRoles {
  id: string;
  name: string;
  ownerOrganization: Maybe<Organization>;
  roleAssignments: UserRoleAssignment[];
}

export default defineComponent({
  components: {
    Table,
    Tooltip,
  },
  setup(_, { root }) {
    const user = injectStrict(ProfileKey);

    const mappedRoles = Object.values(
      user.roleAssignments?.reduce(function (
        map: { [id: string]: MappedRoles },
        obj
      ) {
        if (obj && obj.role?.application) {
          const mapped = map[obj.role.application.id];
          if (mapped == null) {
            map[obj.role?.application?.id] = {
              id: obj.role.application.id,
              name: obj.role.application.name ?? "",
              ownerOrganization: obj.role.application.ownerOrganization,
              roleAssignments: [obj],
            };
          } else {
            map[obj.role.application.id] = {
              ...mapped,
              roleAssignments: [...mapped.roleAssignments, obj],
            };
          }
        }

        return map;
      },
      {}) ?? {}
    );

    return {
      mappedRoles: mappedRoles.map((element) => {
        return {
          ...element,
          _showDetails: false,
        };
      }),
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          label: root.$tc("applications.fields.name"),
        },
        {
          key: "ownerOrganization.name",
          label: root.$tc("applications.fields.owner"),
        },
        {
          key: "roleAssignments",
          label: root.$tc("roles.fields.users_count"),
        },
      ]),
    };
  },
});
