var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{attrs:{"fields":_vm.fields,"items":_vm.mappedRoles},scopedSlots:_vm._u([{key:"cell(roleAssignments)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.length)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":item._showDetails ? 'chevron-down' : 'chevron-right'},on:{"click":function($event){item._showDetails = !item._showDetails}}}),_vm._v(" "+_vm._s(value)+" ")]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},_vm._l((item.roleAssignments),function(assignment,index){return _c('b-badge',{key:index,staticClass:"mr-2"},[_vm._v(_vm._s(assignment.role.name))])}),1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }