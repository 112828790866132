






























import {
  computed,
  defineComponent,
  provide,
  reactive,
  ref,
  toRef,
  watch,
} from "@vue/composition-api";
import Button from "@/components/Button.vue";
import ContentCard from "@/components/ContentCard.vue";
import Tapbar from "@/components/Tapbar.vue";
import ITabContent from "@/interfaces/ITabContent";
import ProfileDataVue from "./Components/ProfileData.vue";
import { ProfileKey } from "./symbols";
import ChangelogTableVue from "@/components/ChangelogTable.vue";
import { EditableKey, IdKey } from "@/symbols";
import { useGetUserProfileQuery, User } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import useVuelidate from "@vuelidate/core";
import useUpdateUserMutation from "../Users/Composables/useUpdateUserMutation";
import useToast from "../Applications/Composables/useToast";
import ProfileApplicationsVue from "./Components/ProfileApplications.vue";
import ProfileSimulationVue from "./Components/ProfileSimulation.vue";
import useProfile from "@/composables/useProfile";

export default defineComponent({
  components: {
    Button,
    ContentCard,
    Tapbar,
  },
  setup(_, { root }) {
    // Singletons
    const { showSuccess } = useToast();

    // Component Refs
    const v$ = useVuelidate();
    const hasChanges = ref<boolean | null>(null);

    // Component Data
    const user = reactive<User>({
      firstname: "",
      lastname: "",
      email: "",
      description: "",
      organization: {},
      roleAssignments: [],
      applicationAssignments: [],
    });

    const { isCentralAdmin, isLocalAdmin, isAppAdmin, isSupportAdmin } =
      useProfile();

    const { result, loading } = useGetUserProfileQuery();
    const isEditable = computed(() => false);
    const updateMutation = useUpdateUserMutation(user);

    // Methods
    provide(ProfileKey, user);
    provide(IdKey, toRef(user, "id"));
    provide(EditableKey, isEditable);

    updateMutation.onDone(() => {
      showSuccess(root.$tc("success.updated_profile"));
      hasChanges.value = false;
    });

    watch(
      () => useResult(result).value,
      (newValue) => {
        if (newValue?.user) Object.assign(user, newValue.user);
      },
      { immediate: true }
    );

    watch(
      () => [user.firstname, user.lastname, user.description],
      () => {
        if (loading.value) return;
        if (hasChanges.value == null) {
          hasChanges.value = false;
        } else {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );

    return {
      user,
      currentTab: ref(0),
      tabs: computed<ITabContent[]>(() => [
        {
          title: root.$tc("common.steps.data"),
          component: ProfileDataVue,
        },
        {
          title: root.$tc("applications.title"),
          component: ProfileApplicationsVue,
        },
        {
          component: ProfileSimulationVue,
          title: root.$tc("profile.fields.token_simulation"),
        },
        {
          title: root.$tc("history.title"),
          component: ChangelogTableVue,
          visible:
            isCentralAdmin.value ||
            isSupportAdmin.value ||
            isLocalAdmin.value ||
            isAppAdmin.value,
        },
      ]),
    };
  },
});
