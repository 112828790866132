import { ApolloError } from "@apollo/client";
import { useToast } from "vue-toastification/composition";
import { ToastContent } from "vue-toastification/src/types";
import SuccesToast from "@/components/SuccessToast.vue";

export default function () {
  const toast = useToast();

  function showError(error: ApolloError) {
    toast.error(
      `${error.message.toString()}\n\nInner exceptions: ${error.graphQLErrors
        .map((element) => JSON.stringify(element))
        .toString()}\n\n Network errors: ${error.networkError?.message}`
    );
  }

  function showSuccess(message: string) {
    const content: ToastContent = {
      component: SuccesToast,
      props: {
        message: message,
      },
    };

    toast(content, {
      timeout: 5000,
      toastClassName: "alert alert-success dismissable px-4 w-100",
    });
  }

  function showErrorMessage(error: string) {
    toast.error(error);
  }

  return {
    showError,
    showErrorMessage,
    showSuccess,
  };
}
