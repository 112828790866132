import { User, useUpdateUserMutation } from "@/graphql/types";

// Cache updates is handled automatically by Apollo
export default function (user: User) {
  const mutation = useUpdateUserMutation({});

  function call() {
    mutation.mutate({
      input: {
        userId: user.id,
        firstname: user.firstname ?? "",
        lastname: user.lastname ?? "",
        description: user.description ?? "",
        email: user.email ?? "",
      },
    });
  }

  return {
    ...mutation,
    call,
  };
}
